import React, { useLayoutEffect } from 'react';
import { PwaPrompt as OriginalPwaPrompt, useShouldShowPrompt } from 'react-ios-pwa-prompt-ts';

export interface PwaPromptProps {
  browser: any;
  standalone: boolean;
}

const PwaPrompt: React.FC<PwaPromptProps> = ({ browser, standalone }: PwaPromptProps) => {
  const { setIosPwaPrompt } = useShouldShowPrompt({
    promptLocalStorageKey: 'roselandPwaPrompt',
    promptOnVisit: 1,
    timesToShow: 5
  });

  /* Overrides builtin functionality to ensure we can handle recent versions of iPad */
  useLayoutEffect(() => {
    if (browser) {
      const isIOS =
        browser.platform?.vendor === 'Apple' &&
        (browser.platform?.type === 'mobile' || browser.platform?.type === 'tablet') &&
        !standalone;
      setIosPwaPrompt((prevState) => {
        const currentVisits = prevState?.visits ?? 0;
        return {
          ...prevState,
          isiOS: isIOS,
          visits: isIOS && !prevState?.isPermanentlyDismissed ? currentVisits + 1 : currentVisits
        };
      });
    }
  }, [setIosPwaPrompt, browser, standalone]);

  return browser ? (
    <OriginalPwaPrompt
      copyAddHomeButtonLabel="2) Press 'Add to Home Screen'."
      copyBody="This website has app functionality. Add it to your home screen."
      copyClosePrompt="Cancel"
      copyShareButtonLabel="1) Press the 'Share' button on the menu bar."
      copyTitle="Add to Home Screen"
      delay={1000}
      permanentlyHideOnDismiss={false}
      promptLocalStorageKey="roselandPwaPrompt"
      skipStorageUpdate={true}
      promptOnVisit={1}
      timesToShow={5}
      transitionDuration={400}
    />
  ) : (
    <></>
  );
};

export default PwaPrompt;
