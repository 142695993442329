import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CsvProps } from '../../components/DownloadCsv';
import { setAppLoading } from '../../store/app';
import { loadDefaultSort, useFilterQueryCriteriaFromUrl } from '../../utils/filter';

const SCHOOL_BOARDS_LISTING = gql`
  query SchoolBoardsListing(
    $filter: MViewSchoolBoardTotalFilter!
    $offset: Int!
    $orderBy: [MViewSchoolBoardTotalsOrderBy!]
  ) {
    schoolBoards: mViewSchoolBoardTotalsConnection(first: 150, filter: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        schoolBoardDistrict
        schoolBoardDistrictId
        state
      }
      totalCount
    }
  }
`;

const SCHOOL_BOARD_MEMBER_DETAIL_QUERY = gql`
  query SchoolBoardDetail($voterId: UUID!) {
    schoolBoardMember: viewSchoolBoardsConnection(filter: { voterId: { equalTo: $voterId } }) {
      nodes {
        boardMember
        aka
        cityFromBrian
        cityFromSd
        age
        firstElected
        firstAppointed
        nextElection
        profession
        note
      }
    }
  }
`;

const STATE_COUNT_QUERY = gql`
  query StateCounts {
    states: mViewSchoolBoardTotalsConnection(filter: { state: { isNull: false } }, orderBy: NATURAL) {
      groupedAggregates(groupBy: STATE) {
        keys
        sum: distinctCount {
          total: schoolBoardDistrict
        }
      }
    }
  }
`;

export const useSchoolBoardById = (id: string) => {
  return useQuery(SCHOOL_BOARDS_LISTING, {
    variables: {
      filter: { schoolBoardDistrictId: { equalTo: id } },
      offset: 0,
      orderBy: 'NATURAL'
    }
  });
};

export const useSchoolBoardDetailByVoterId = () => {
  return useLazyQuery(SCHOOL_BOARD_MEMBER_DETAIL_QUERY);
};

export const useStateSchoolBoardsCountQuery = () => {
  return useLazyQuery(STATE_COUNT_QUERY);
};

export const useAllSchoolBoardsListingQuery = (offset: number, _sort: string[]) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'db');

  const [sort, setSort] = useState(_sort);

  useEffect(() => {
    if (sort.length < 1) {
      setSort(loadDefaultSort(searchParams, sort, ['SCHOOL_BOARD_DISTRICT_ASC']));
      setSearchParams(searchParams);
    }
  }, [sort, setSearchParams, searchParams]);

  const result = useQuery(SCHOOL_BOARDS_LISTING, {
    variables: {
      ...queryCriteria,
      offset: offset,
      orderBy: sort
    }
  });

  // todo: add result;
  useEffect(() => {
    setAppLoading(true);
    result.refetch(queryCriteria).finally(() => setAppLoading(false));
  }, [queryCriteria]);

  return result;
};

const SCHOOL_BOARDS_FOR_DOWNLOAD = gql`
  query SchoolsDownload(
    $first: Int!
    $filter: MViewSchoolBoardTotalFilter!
    $offset: Int!
    $orderBy: [MViewSchoolBoardTotalsOrderBy!]
  ) {
    csv: mViewSchoolBoardTotalsConnection(first: $first, filter: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        schoolBoardDistrict
        state
        schoolBoardDistrictId
      }
    }
  }
`;

export const useCsvPropsForDownload = () => {
  const [searchParams] = useSearchParams();
  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'db');

  const csvProps: CsvProps = {
    query: SCHOOL_BOARDS_FOR_DOWNLOAD,
    vars: {
      ...queryCriteria,
      offset: 0,
      first: 10000,
      orderBy: ['SCHOOL_BOARD_DISTRICT_ASC']
    }
  };

  return csvProps;
};

const TOTALS_QUERY = gql`
  query TotalsQuery {
    dashboardTotals: mViewSchoolBoardTotalsConnection {
      totalCount
    }
  }
`;

export const useSchoolBoardTotalsQuery = () => {
  return useQuery(TOTALS_QUERY, {});
};
