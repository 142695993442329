import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CsvProps } from '../../components/DownloadCsv';
import { loadDefaultSort, useFilterQueryCriteriaFromUrl } from '../../utils/filter';

const NURSING_HOMES_LISTING = gql`
  query NursingHomesListing($filter: MViewNursingHomeFilter!, $offset: Int!, $orderBy: [MViewNursingHomesOrderBy!]) {
    nursingHomes: mViewNursingHomes(first: 150, filter: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        id
        name
        address
        cityId
        city
        countyId
        county
        state
        zip
        beds
        averageNumberOfResidentsPerDay
        voterCount
      }
      totalCount
    }
  }
`;

export const useNursingHomesQuery = (offset: number, _sort: string[]) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'db');

  const [sort, setSort] = useState(_sort);

  useEffect(() => {
    if (sort.length < 1) {
      setSort(loadDefaultSort(searchParams, sort, ['NAME_ASC']));
      setSearchParams(searchParams);
    }
  }, [sort, setSearchParams, searchParams]);

  const filter = {
    variables: {
      ...queryCriteria,
      offset: offset,
      orderBy: sort
    },
    context: { version: '2' }
  };

  const result = useQuery(NURSING_HOMES_LISTING, filter);

  return result;
};

const STATE_COUNT_QUERY = gql`
  query StateCounts {
    states: mViewNursingHomes(filter: { state: { isNull: false } }, orderBy: NATURAL) {
      groupedAggregates(groupBy: STATE) {
        keys
        sum: distinctCount {
          total: id
        }
      }
    }
  }
`;

export const useStateNursingHomesCountQuery = () => {
  return useLazyQuery(STATE_COUNT_QUERY, { context: { version: '2' } });
};

const COUNTIES_QUERY = gql`
  query CountiesByState($filter: MViewNursingHomeFilter!) {
    counties: mViewNursingHomes(filter: $filter) {
      groupedAggregates(groupBy: [COUNTY_ID, COUNTY, STATE]) {
        keys
        sum: distinctCount {
          total: id
        }
      }
    }
  }
`;

export const useNursingHomesCounties = () => {
  return useLazyQuery(COUNTIES_QUERY, { context: { version: '2' } });
};

const CITIES_QUERY = gql`
  query CitiesForState($filter: MViewNursingHomeFilter!) {
    cities: mViewNursingHomes(filter: $filter) {
      groupedAggregates(groupBy: [CITY_ID, CITY, COUNTY, COUNTY_ID, STATE]) {
        keys
        sum: distinctCount {
          total: id
        }
      }
    }
  }
`;

export const useNursingHomesCities = () => {
  return useLazyQuery(CITIES_QUERY, { context: { version: '2' } });
};

const TOTALS_QUERY = gql`
  query TotalsQuery {
    dashboardTotals: mViewNursingHomes {
      totalCount
    }
  }
`;

export const useNursingHomeTotalsQuery = () => {
  return useQuery(TOTALS_QUERY, { context: { version: '2' } });
};

const NURSING_HOMES_FOR_DOWNLOAD = gql`
  query NursingHomesDownload(
    $first: Int!
    $filter: MViewNursingHomeFilter!
    $offset: Int!
    $orderBy: [MViewNursingHomesOrderBy!]
  ) {
    csv: mViewNursingHomes(first: $first, filter: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        name
        address
        city
        state
        zip
        beds
        averageNumberOfResidentsPerDay
        voterCount
      }
    }
  }
`;

export const useCsvPropsForDownload = () => {
  const [searchParams] = useSearchParams();
  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'db');

  const csvProps: CsvProps = {
    query: NURSING_HOMES_FOR_DOWNLOAD,
    vars: {
      ...queryCriteria,
      offset: 0,
      first: 10000,
      orderBy: ['NAME_ASC']
    },
    context: { version: '2' }
  };

  return csvProps;
};
