import React, { memo, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import Option from '../../common/Option';
import { ClassNameProps } from '../../common/Props';
import { useOrganizationsForFactoids } from '../../gql/factoid';
import { useOrganizationsByIdsQuery } from '../../gql/organization';
import { useAddFilterIndicator } from '../../store/filter';
import { mergeRecords, useGeoOptionFormatter } from '../../utils/filter';
import { getSearchParamsAsOptions, useSelectSearch } from '../../utils/search';
import { simpleNameMapper } from '../FilterIndicator/mappers';
import { FormSelect } from '../Form';

const FactoidOrganizationSelect = styled(
  memo(({ className }: {} & ClassNameProps) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const PARAM_KEY = 'factoids.organizationId.keyword-n';

    const [value, setValue] = useState<Option[]>();

    const optionFormatter = useGeoOptionFormatter(false);

    const [getOrgs, { data, loading }] = useOrganizationsForFactoids();

    useEffect(() => {
      getOrgs();
    }, [getOrgs, searchParams]);

    useEffect(() => {
      if (data && !loading) {
        if (searchParams.has(PARAM_KEY)) {
          const orgs =
            data?.result?.esResult?.aggregations?.agg_custom_field?.agg_value?.buckets ||
            data?.result?.esResult?.aggregations?.agg_custom_field?.root_filter?.agg_value?.buckets ||
            [];
          const vals = searchParams.getAll(PARAM_KEY);
          vals.forEach((v) => {
            const existing = orgs.find((f: any) => f.key[1] === v);
            if (!existing) {
              // @ts-ignore: expecting only 1 arg
              searchParams.delete(PARAM_KEY, v);
            }
          });
          setSearchParams(searchParams);
        }
      }
    }, [data, loading, searchParams, setSearchParams]);

    const orgOptions = useMemo(() => {
      const orgs =
        data?.result?.esResult?.aggregations?.agg_custom_field?.agg_value?.buckets ||
        data?.result?.esResult?.aggregations?.agg_custom_field?.root_filter?.agg_value?.buckets ||
        [];

      return mergeRecords(
        orgs,
        (c: any) => c.key[0],
        (c: any) => c.key[1],
        (c: any) => c.top_reverse_nested.doc_count
      );
    }, [data]);

    useEffect(() => {
      if (orgOptions && !loading) {
        setValue(getSearchParamsAsOptions(searchParams, PARAM_KEY, orgOptions as any));
      }
    }, [orgOptions, loading, setValue, searchParams]);

    const handleChange = useSelectSearch(PARAM_KEY, []);

    useAddFilterIndicator({
      [PARAM_KEY]: {
        paramKey: PARAM_KEY,
        label: 'associated with',
        nameMapper: simpleNameMapper,
        queryHook: useOrganizationsByIdsQuery
      }
    });

    return (
      <div className={className}>
        {loading ? (
          <FormSelect placeholder="Associated Organizations loading..." />
        ) : (
          <FormSelect
            placeholder="Associated Organizations..."
            formatOptionLabel={optionFormatter}
            isLoading={loading}
            onChange={handleChange}
            portalTarget={document.querySelector(`.${className}`)}
            isClearable
            isMulti={true}
            value={value}
            options={orgOptions}
            className={`${className} org-select`}
          />
        )}
      </div>
    );
  })
)`
  position: relative;
  margin-bottom: 8px;
  .form-select__menu {
    transform: translateY(-7px);
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    background-color: var(--primary-light);
    overflow: hidden;
    .form-select__option {
      border-bottom: 1px solid var(--filter-bg);
      color: var(--gray-light);
    }
  }
`;

export default FactoidOrganizationSelect;
