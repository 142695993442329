import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CsvProps } from '../../components/DownloadCsv';
import { loadDefaultSort, useFilterQueryCriteriaFromUrl } from '../../utils/filter';

const NCOA_LISTING = gql`
  query NcoaListing($filter: MViewNcoaUnregisteredFilter!, $offset: Int!, $orderBy: [MViewNcoaUnregisteredsOrderBy!]) {
    people: mViewNcoaUnregistereds(first: 150, filter: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        id
        firstName
        middleName
        lastName
        suffix
        birthdate
        deathdate
        phone
        sex
        twitter
        facebook
        linkedin
        instagram
        moveDate
        moveType
        partyAffiliation
        registrationStatus
        registrationDate
        suid
        email
        addressId
        address
        unit
        cityId
        city
        countyId
        county
        zipId
        zip
        state
        lat
        lon
        precinctId
        precinct
        stateHouseDistrictId
        stateHouseDistrict
        stateSenateDistrictId
        stateSenateDistrict
        congressionalDistrictId
        congressionalDistrict
        neighborhoodId
        neighborhood
        subdivisionId
        subdivision
        updatedAt
      }
      totalCount
    }
  }
`;

export const useNcoaQuery = (offset: number, _sort: string[]) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'db');

  const [sort, setSort] = useState(_sort);
  
  useEffect(() => {
    if (sort.length < 1) {
      setSort(loadDefaultSort(searchParams, sort, ['LAST_NAME_ASC', 'FIRST_NAME_ASC']));
      setSearchParams(searchParams);
    }
  }, [sort, setSearchParams, searchParams]);

  const filter = {
    variables: {
      ...queryCriteria,
      offset: offset,
      orderBy: sort
    },
    context: { version: '2' }
  };

  const result = useQuery(NCOA_LISTING, filter);

  return result;
};

const STATE_COUNT_QUERY = gql`
  query StateCounts {
    states: mViewNcoaUnregistereds(filter: { state: { isNull: false } }, orderBy: NATURAL) {
      groupedAggregates(groupBy: STATE) {
        keys
        sum: distinctCount {
          total: id
        }
      }
    }
  }
`;

export const useNcoaStatesQuery = () => {
  return useLazyQuery(STATE_COUNT_QUERY, { context: { version: '2' } });
};

const COUNTIES_QUERY = gql`
  query CountiesByState($filter: MViewNcoaUnregisteredFilter!) {
    counties: mViewNcoaUnregistereds(filter: $filter) {
      groupedAggregates(groupBy: [COUNTY_ID, COUNTY, STATE]) {
        keys
        sum: distinctCount {
          total: id
        }
      }
    }
  }
`;

export const useNcoaCounties = () => {
  return useLazyQuery(COUNTIES_QUERY, { context: { version: '2' } });
};

const CITIES_QUERY = gql`
  query CitiesForState($filter: MViewNcoaUnregisteredFilter!) {
    cities: mViewNcoaUnregistereds(filter: $filter) {
      groupedAggregates(groupBy: [CITY_ID, CITY]) {
        keys
        sum: distinctCount {
          total: id
        }
      }
    }
  }
`;

export const useNcoaCities = () => {
  return useLazyQuery(CITIES_QUERY, { context: { version: '2' } });
};

const PRECINCTS_QUERY = gql`
  query PrecinctsForState($filter: MViewNcoaUnregisteredFilter!) {
    precincts: mViewNcoaUnregistereds(filter: $filter) {
      groupedAggregates(groupBy: [PRECINCT_ID, PRECINCT]) {
        keys
        sum: distinctCount {
          total: id
        }
      }
    }
  }
`;

export const useNcoaPrecincts = () => {
  return useLazyQuery(PRECINCTS_QUERY, { context: { version: '2' } });
};

const TOTALS_QUERY = gql`
  query TotalsQuery {
    dashboardTotals: mViewNcoaUnregistereds {
      totalCount
    }
  }
`;

export const useNcoaTotalsQuery = () => {
  return useQuery(TOTALS_QUERY, { context: { version: '2' } });
};

const NCOA_FOR_DOWNLOAD = gql`
  query NcoaDownload(
    $first: Int!
    $filter: MViewNcoaUnregisteredFilter!
    $offset: Int!
    $orderBy: [MViewNcoaUnregisteredsOrderBy!]
  ) {
    csv: mViewNcoaUnregistereds(first: $first, filter: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        firstName
        middleName
        lastName
        suffix
        birthdate
        deathdate
        phone
        sex
        twitter
        facebook
        linkedin
        instagram
        moveDate
        moveType
        partyAffiliation
        registrationStatus
        registrationDate
        suid
        email
        address
        unit
        city
        county
        zip
        state
        lat
        lon
        precinct
        stateHouseDistrict
        stateSenateDistrict
        congressionalDistrict
        neighborhood
        subdivision
      }
    }
  }
`;

export const useCsvPropsForDownload = () => {
  const [searchParams] = useSearchParams();
  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'db');

  const csvProps: CsvProps = {
    query: NCOA_FOR_DOWNLOAD,
    vars: {
      ...queryCriteria,
      offset: 0,
      first: 10000,
      orderBy: ['LAST_NAME_ASC', 'FIRST_NAME_ASC']
    },
    context: { version: '2' }
  };

  return csvProps;
};
