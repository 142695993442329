import { useQuery } from '@apollo/client';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { ELECTION_RESULTS_QUERY } from '.';
import { loadDefaultSort, useFilterQueryCriteriaFromUrl } from '../../utils/filter';
import { sortListBySortParams } from '../../utils/sort';
import { flattenBuckets } from './common';
import { getPrecinctAggregateQuery, getSummaryQuery, getTownshipAggregateQuery } from './electionResultsQuery';
import { parties } from './parties';

export const useElectionResultsSummaryForPrecinctList = () => {
  const { contest, countyId, type, state, year } = useParams();

  const precinctAggregateSummaryQuery = getSummaryQuery();
  const query: any = {
    ...{
      filter: {
        ...precinctAggregateSummaryQuery,
        ...{ query: { bool: { must: [] } } }
      }
    }
  };

  query.filter.query.bool.must = [
    { match: { contest_name: { query: contest } } },
    { match: { type: { query: type } } },
    { match: { county_id: { query: countyId } } },
    { match: { state: { query: state } } },
    { match: { year: { query: year } } }
  ];

  const result = useQuery(ELECTION_RESULTS_QUERY, { variables: query });

  let rows: any = flattenBuckets(result?.data?.result?.aggBuckets || { year: { buckets: [] } }, {});

  rows.sort((a: any, b: any) => {
    let result = parseInt(a.year) - parseInt(b.year);
    if (result === 0) {
      result = a.candidateName.localeCompare(b.candidateName);
    }
    return result;
  });

  return { ...result, ...{ data: { result: rows } } };
};

export const usePrecinctElectionResultsListing = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { year, type, state, contest, countyId } = useParams();

  const [sort, setSort] = useState(searchParams.getAll('sort') || []);

  useEffect(() => {
    if (sort.length < 1) {
      setSort(loadDefaultSort(searchParams, sort, ['PRECINCT_NAME_ASC']));
      setSearchParams(searchParams);
    }
  }, [sort, setSearchParams, searchParams]);

  const query = {
    filter: { ...getPrecinctAggregateQuery(), ...useFilterQueryCriteriaFromUrl(searchParams, 'elasticSearch')?.filter },
    orderBy: sort
  };

  query.filter.query.bool.must = [
    ...(query?.filter?.query?.bool?.must || []),
    { match: { contest_name: { query: contest } } },
    { match: { type: { query: type } } },
    { match: { state: { query: state } } },
    { match: { year: { query: year } } },
    { match: { county_id: { query: countyId } } }
  ];

  const result = useQuery(ELECTION_RESULTS_QUERY, {
    variables: {
      ...query
    }
  });

  let rows: any = flattenBuckets(result?.data?.result?.aggBuckets || { state: { buckets: [] } }, {});

  let formattedResult: any[] = [];
  rows?.forEach((a: any) => {
    let existing = formattedResult.find((r: any) => r.precinctName === a.precinctName);
    if (isNil(existing)) {
      formattedResult.push({
        precinctName: a.precinctName,
        [parties[a.party || 'Other']]: parseInt(a.votes)
      });
    } else {
      existing[parties[a.party] || 'Other'] = parseInt(a.votes);
    }
  });

  formattedResult = sortListBySortParams(formattedResult, sort);

  return { ...result, ...{ data: { precincts: formattedResult } } };
};

export const useTownshipElectionResultsListing = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { year, type, state, contest, countyId } = useParams();

  const [sort, setSort] = useState(searchParams.getAll('sort') || []);
  
  useEffect(() => {
    if (sort.length < 1) {
      setSort(loadDefaultSort(searchParams, sort, ['TOWNSHIP_NAME_ASC']));
      setSearchParams(searchParams);
    }
  }, [sort, setSearchParams, searchParams]);

  const query = {
    filter: { ...getTownshipAggregateQuery(), ...useFilterQueryCriteriaFromUrl(searchParams, 'elasticSearch')?.filter },
    orderBy: sort
  };

  query.filter.query.bool.must = [
    ...(query?.filter?.query?.bool?.must || []),
    { match: { contest_name: { query: contest } } },
    { match: { type: { query: type } } },
    { match: { state: { query: state } } },
    { match: { year: { query: year } } },
    { match: { county_id: { query: countyId } } }
  ];

  const result = useQuery(ELECTION_RESULTS_QUERY, {
    variables: {
      ...query
    }
  });

  let rows: any = flattenBuckets(result?.data?.result?.aggBuckets || { state: { buckets: [] } }, {});

  let formattedResult: any[] = [];
  rows?.forEach((a: any) => {
    let existing = formattedResult.find((r: any) => r.townshipId === a.townshipId);
    if (isNil(existing)) {
      formattedResult.push({
        townshipId: a.townshipId,
        townshipName: a.townshipName,
        [parties[a.party || 'Other']]: parseInt(a.votes)
      });
    } else {
      existing[parties[a.party] || 'Other'] = parseInt(a.votes);
    }
  });

  formattedResult = sortListBySortParams(formattedResult, sort);

  return { ...result, ...{ data: { townships: formattedResult } } };
};
