import { isNestedPath, toSnakeCase } from '../../utils/format';

export const includesIgnoreCaseDbFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  if (isNestedPath(field)) {
    const path = field.substring(0, field.indexOf('.'));
    const rest = field.substring(field.indexOf('.') + 1);

    return { [path]: includesIgnoreCaseDbFilter(rest, searchParams, filterValue) };
  }
  return {
    [field]: {
      includesInsensitive: filterValue[0]
    }
  };
};

export const includesIgnoreCaseEsFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  const snkField = toSnakeCase(field);
  const values = filterValue[0].split(' ');

  let should: any[] = [];
  values.forEach((value: string) => {
    should.push({
      wildcard: {
        [snkField]: {
          value: `*${value}*`,
          case_insensitive: true
        }
      }
    });
  });

  return {
    must: [
      {
        bool: {
          should,
          minimum_should_match: values.length
        }
      }
    ]
  };
};
