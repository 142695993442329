import { useKeycloak } from '@react-keycloak/web';
import { FlagsProvider } from 'flagged';
import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandler from './components/ErrorHandler';
import Loading from './components/Loading';
import { RouteMatcher } from './components/routes/RouteMatcher';
import features from './features.json';
import { useAppLoading } from './store/app';
import useBrowserDetection from './utils/browser';
import PwaPrompt from './components/PwaPrompt';

const lookupVersion = (callback: Function) => {
  loadVersion();
  async function loadVersion() {
    fetch('/version.json')
      .then((response) => response.json())
      .then((json) => {
        callback(json);
      });
  }
};

export type VersionState = {
  version: string;
  date: string;
  sha: string;
};

const App = () => {
  const { initialized } = useKeycloak();
  const appLoading = useAppLoading();
  const browser = useBrowserDetection();
  const [standalone, setStandalone] = useState<boolean>(false);

  const [versionLoaded, setVersionLoaded] = useState<boolean>(false);
  const [versionState, setVersionState] = useState<VersionState>({ version: '', date: '', sha: '' });

  useEffect(() => {
    if (!versionLoaded) {
      lookupVersion((json) => {
        setVersionState({ version: json.version, date: json.date, sha: json.sha });
        setVersionLoaded(true);
      });
    }
  }, [versionLoaded]);

  useEffect(() => {
    const isStandalone = !!navigator && 'standalone' in navigator && navigator.standalone;
    setStandalone(!!isStandalone);
  }, []);

  return !initialized && !versionLoaded ? (
    <Loading />
  ) : (
    <>
      <PwaPrompt browser={browser} standalone={standalone} />
      <FlagsProvider features={features}>
        <ErrorBoundary FallbackComponent={ErrorHandler}>
          <RouteMatcher versionState={versionState} />
        </ErrorBoundary>
      </FlagsProvider>
      {appLoading && <Loading />}
    </>
  );
};

export default App;
