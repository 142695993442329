import { toSnakeCase } from '../../utils/format';

export const geoRadiusDbFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  throw new Error('geoRadius is unimplemented for elasticSearch source');
};
export const geoRadiusEsFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  const snkField = toSnakeCase(field);

  return {
    filter: {
      geo_distance:{
        distance: `${filterValue[0]}m`,
        [snkField]:{
          lat: parseFloat(filterValue[1]),
          lon: parseFloat(filterValue[2])
        }
      }
    }
  };
};
