import { isArray, isNil } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import Option from '../../common/Option';
import { useFeatureFlag } from '../../utils/features';
import { useFilterOptions } from '../../utils/filter';
import { FormSelect } from '../Form';
import FilterOption from './FilterOption';
import FilterTitle from './FilterTitle';

interface FilterTypeProps {
  className?: string;
  query: string;
  filterType: string;
  filter: any;
  source: string;
  apiVersion: string;
}

const FilterType = styled(({ className, query, filterType, filter, source, apiVersion }: FilterTypeProps) => {
  const [options, setOptions] = useState<Option[]>([]);
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [optionsLoaded, setOptionsLoaded] = useState(false);

  const isQueryOptions = useMemo(() => !isArray(filter.options), [filter]);
  const filterOptions = useFilterOptions(filter.options, !expanded && !isQueryOptions);

  let [searchParams, setSearchParams] = useSearchParams({});

  let [_priorSearchParams, setPriorSearchParams] = useState<URLSearchParams>(); // eslint-disable-line @typescript-eslint/no-unused-vars

  const urlKey = useMemo(() => `${filter.field}-${filter.type}`, [filter.field, filter.type]);

  const enabled = filter.feature ? useFeatureFlag(filter.feature) : true;

  const handleChange = useCallback(
    (v: Option[]) => {
      const newParams = new URLSearchParams(searchParams);
      newParams.delete(urlKey);

      v.forEach((opt) => {
        newParams.append(urlKey, opt.value);
      });

      setSearchParams(newParams);
    },
    [urlKey, setSearchParams, searchParams]
  );

  // Cache the state of any driver fields.
  useEffect(() => {
    if (!isNil(filter.drivers)) {
      filter.drivers.forEach((driver: string) => {
        // if the current search params has the key
        for (const key of searchParams.keys()) {
          if (key.startsWith(driver)) {
            setOptionsLoaded(false);
          }
        }

        setPriorSearchParams((prior: URLSearchParams | undefined) => {
          if (!isNil(prior)) {
            for (const key of prior!.keys()) {
              if (key.startsWith(driver)) {
                setOptionsLoaded(false);
              }
            }
          }
          return searchParams;
        });
      });
    }
  }, [searchParams, filter.drivers, setPriorSearchParams, setOptionsLoaded]);

  useEffect(() => {
    const load = async () => {
      setOptions((await filterOptions) as Option[]);
      setLoading(false);
      setOptionsLoaded(true);
    };
    if (!optionsLoaded && (expanded || isQueryOptions)) {
      load();
    } else {
      setLoading(false);
    }
  }, [filterOptions, setOptions, setLoading, expanded, isQueryOptions, setOptionsLoaded, optionsLoaded]);

  return loading || !enabled ? null : (
    <div className={`filter-type filter-type--${expanded ? 'expanded' : 'collapsed'} ${className}`}>
      <FilterTitle expanded={expanded} onClick={!isQueryOptions ? () => setExpanded(!expanded) : null}>
        {filter.title}
      </FilterTitle>
      {isQueryOptions && (
        <FormSelect
          isMulti
          isLoading={loading}
          onChange={handleChange}
          value={searchParams.getAll(urlKey).map((param) => ({ label: param, value: param }))}
          options={options}
        />
      )}
      <div style={{ display: expanded && !isQueryOptions ? 'block' : 'none' }}>
        {!isQueryOptions &&
          options.map((option, i) => (
            <FilterOption
              source={source}
              key={i}
              skipCount={isQueryOptions || filter.skipCount}
              query={query}
              filterType={filterType}
              label={option.label}
              value={option.value}
              type={filter.type}
              field={filter.field}
              apiVersion={apiVersion}
              exclusive={filter.exclusive}
            />
          ))}
      </div>
    </div>
  );
})`
  &.filter-type.filter-type--expanded {
    background-color: #000;
    border-radius: var(--border-radius-xs);

    .filter__title {
      margin-top: 0;
    }

    padding-bottom: 6px;
    padding-top: 6px;
    margin-top: 13px;
  }
`;

export default FilterType;
