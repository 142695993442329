import { MatchType } from '../../components/EntitySearch/MatchType';
import { toSnakeCase } from '../../utils/format';

export const matchTypeDbFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  throw new Error('MatchTypeDbFilter is not implemented');
};

export const matchTypeEsFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  const snkField = toSnakeCase(field);

  const matchType = searchParams.get('matchType') || MatchType.any;

  if (matchType === MatchType.any) {
    let matchFields: any[] = [
      {
        match: {
          [snkField]: {
            query: `${filterValue[0]}`
          }
        }
      }
    ];

    return {
      must: matchFields
    };
  } else if (matchType === MatchType.exact) {
    const match = {
      match_phrase_prefix: {
        [snkField]: {
          query: filterValue[0]
        }
      }
    };

    return {
      must: [match]
    };
  }
};
