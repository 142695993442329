import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { loadDefaultSort, useFilterQueryCriteriaFromUrl } from '../../utils/filter';

// NB: viewDocuments is for accessing parsed documents via the opensearch api
const DOCUMENTS_QUERY = gql`
  query DocumentQuery($first: Int!, $filter: JSONObject!, $offset: Int!, $orderBy: [ViewDocumentsOrderBy!]) {
    result: viewDocumentsConnection(first: $first, elasticSearch: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        author
        category
        createdDate
        datePublished
        documentId
        edition
        fileName
        highlightContent
        isbn10
        isbn13
        language
        pageNumber
        publisher
        organizationId
        organizationName
        scannedDate
        subCategory
        title
        totalPages
        url
      }
      totalCount
    }
  }
`;

const DOCUMENT_TOTALS_QUERY = gql`
  query DocumentTotalsQuery {
    dashboardTotals: viewDocumentsConnection(elasticSearch: {}) {
      totalCount
    }
  }
`;

export const useDocumentListing = (offset: number, sort: string[]) => {
  const [searchParams] = useSearchParams();

  const contentSearch = searchParams.getAll('content-mt');
  const isContentSearch = useMemo(() => {
    return contentSearch.length > 0;
  }, [contentSearch]);

  const pageNumCondition = useMemo(() => {
    return isContentSearch ? undefined : { 'pageNumber-numeq': ['1'] };
  }, [isContentSearch]);

  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'elasticSearch', pageNumCondition);

  if (isContentSearch) {
    queryCriteria.filter.highlight = {
      fields: {
        content: {}
      }
    };
  }

  return useQuery(DOCUMENTS_QUERY, {
    variables: {
      ...queryCriteria,
      first: 150,
      offset: offset || 0,
      orderBy: sort?.length < 1 ? ['TITLE_ASC'] : sort
    },
    fetchPolicy: 'no-cache'
  });
};

export const useDocumentTotalsQuery = () => {
  return useQuery(DOCUMENT_TOTALS_QUERY, {});
};

const DOCUMENT_ADMIN_LIST_QUERY = gql`
  query DocumentAdminQuery($filter: DocumentFilter!, $offset: Int!, $orderBy: [DocumentsOrderBy!]) {
    result: documentsConnection(first: 150, filter: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        createdAtMs
        error
        id
        metadata
        status
        storageLocation
        updatedAtMs
        userId
        userInfo {
          firstName
          lastName
          email
        }
      }
      totalCount
    }
  }
`;

export const useDocumentAdminListingQuery = (offset: number, sort: string[]) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'db');

  if (sort.length < 1) {
    sort = loadDefaultSort(searchParams, sort, ['CREATED_AT_MS_ASC']);
    setSearchParams(searchParams);
  }

  const filter = {
    pollInterval: 3000,
    variables: {
      ...queryCriteria,
      offset: offset,
      orderBy: sort
    }
  };
  return useQuery(DOCUMENT_ADMIN_LIST_QUERY, filter);
};

const GET_DOCUMENT_ADMIN_QUERY = gql`
  query GetDocumentAdminQuery($id: UUID!) {
    result: document(id: $id) {
      createdAtMs
      error
      id
      metadata
      status
      storageLocation
      updatedAtMs
    }
  }
`;

export const useGetDocumentMetadataById = () => {
  return useLazyQuery(GET_DOCUMENT_ADMIN_QUERY);
};

const CLEAR_DOCUMENT_CACHE = gql`
  mutation ClearDocumentCache {
    clearCache(input: { entities: ["Document", "DocumentsConnection"] })
  }
`;

export const useClearDocumentCache = () => {
  return useMutation(CLEAR_DOCUMENT_CACHE);
};
