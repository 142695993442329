import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowUpRightFromSquare, faPencil } from '@fortawesome/free-solid-svg-icons';
import { isNil } from 'lodash';
import React, { memo, useMemo } from 'react';
import { Link as RRDLink, LinkProps } from 'react-router-dom';
import styled from 'styled-components';
import { env } from '../../env';
import { useFeatureFlag } from '../../utils/features';
import { formatAddress } from '../../utils/format';
import Icon from '../Icon';
import { paths } from '../routes/routes';
import RequiresRoles from '../Security/RequiresRoles';

export const MAPS_BASE = 'https://www.google.com/maps/place/';
const A = styled(({ children, ...rest }: LinkProps) => {
  return <RRDLink {...rest}>{children}</RRDLink>;
})`
  transition: all 0.3s;
  text-decoration: none;

  color: var(--link-color);
  &:hover {
    color: var(--link-color-hover);
  }
`;

const Link = styled(({ children, ...props }: any) => {
  return <a {...props}>{children}</a>;
})`
  transition: all 0.3s;
  text-decoration: none;

  color: var(--link-color);
  &:hover {
    color: var(--link-color-hover);
  }
`;
interface MapLinkProps {
  className?: string;
  address: string;
  city: string;
  state: string;
  zip: string | number;
}

const MapLink = styled(({ className, address, city, state, zip }: MapLinkProps) => {
  const href = useMemo(() => {
    return `${MAPS_BASE}${address}+${city}+${state}+${zip}`;
  }, [address, city, state, zip]);

  return (
    <Link target="_blank" href={href} className={`map-link ${className}`}>
      {formatAddress(address, city, state, zip)}
    </Link>
  );
})`
  transition: all 0.3s;
  text-decoration: none;

  text-transform: capitalize;
  color: var(--link-color);
  &:hover {
    color: var(--link-color-hover);
  }
`;

const MapPopoutLink = styled(({ className, address, city, state, zip }: MapLinkProps) => {
  const href = useMemo(() => {
    return `${MAPS_BASE}${address}+${city}+${state}+${zip}`;
  }, [address, city, state, zip]);

  return (
    <Link target="_blank" href={href} className={`map-link-popout ${className}`}>
      <i>View larger map</i>&nbsp;
      <Icon icon={faArrowUpRightFromSquare as IconProp} className="icon--prefix" />
    </Link>
  );
})`
  margin-left: 10px;
  font-size: 12px;
`;

export interface WebsiteProps {
  url: string;
  displayText?: string;
  className: string;
}

const Website = styled(({ url, displayText, className }: WebsiteProps) => {
  return url && '-' !== url ? (
    <a target="_blank" href={`${url}`} className={`${className}`} rel="noreferrer">
      {displayText ? displayText : url}
    </a>
  ) : (
    ''
  );
})`
  transition: all 0.3s;
  text-decoration: none;

  color: var(--link-color);
  &:hover {
    color: var(--link-color-hover);
  }
`;

export interface LimparProps {
  type: string;
  id: string;
  to: string;
  className: string;
}

const Limpar = styled(({ type, id, to, className }: LimparProps) => {
  const limparActive = useFeatureFlag('limpar');

  if (!limparActive) {
    return null;
  }

  to = to && to.endsWith('undefined') ? '' : to;
  let url = to && to !== 'null' ? to : id && id !== 'null' ? `/${type}/${id}` : ``;

  return url ? (
    <RequiresRoles roles={['admin-role']}>
      <a target="_blank" href={`${env.REACT_APP_LIMPAR_BASE_URL}${url}`} className={`${className}`} rel="noreferrer">
        <Icon icon={faPencil} />
      </a>
    </RequiresRoles>
  ) : (
    ''
  );
})`
  padding-left: 10px;
`;

const GeoLink: React.FC<Omit<LinkProps, 'to'> & { type: string; id: string; stateAbbr?: string }> = memo(
  ({ type, stateAbbr, id, children, ...rest }) => {
    if (isNil(id)) {
      return <>{children}</>;
    }
    return (
      <A {...rest} to={`${paths.geographies}${type}${isNil(stateAbbr) ? '' : '/' + stateAbbr}/${id}`}>
        {children}
      </A>
    );
  }
);

export default A;
export { Link, GeoLink, MapLink, MapPopoutLink, Website, Limpar };