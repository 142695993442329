import { isNil } from 'lodash';
import { env } from '../env';
import { DataSource } from '../utils/filter';
import { caseNameDbFilter, caseNameEsFilter } from './processors/case';
import { casesByLawfirmDbFilter, casesByLawfirmEsFilter } from './processors/casesByLawfirm';
import { dateRandgeDbFilter, dateRangeEsFilter } from './processors/dateRange';
import { equalsDbFilter, equalsEsFilter } from './processors/equals';
import { equalsIgnoreCaseDbFilter, equalsIgnoreCaseEsFilter } from './processors/equalsIgnoreCase';
import { exactMatchDbFilter, exactMatchEsFilter } from './processors/exactMatch';
import { existsDbFilter, existsEsFilter } from './processors/exists';
import { fieldExistsDbFilter, fieldExistsEsFilter } from './processors/fieldExists';
import { flagDbFilter, flagEsFilter } from './processors/flag';
import { geoRadiusDbFilter, geoRadiusEsFilter } from './processors/geoRadius';
import { greaterThanOrEqualToDbFilter, greaterThanOrEqualToEsFilter } from './processors/greaterThanOrEqualTo';
import { groupByDbFilter, groupByEsFilter } from './processors/groupby';
import { includesIgnoreCaseDbFilter, includesIgnoreCaseEsFilter } from './processors/includesIgnoreCase';
import { lessThanOrEqualToDbFilter, lessThanOrEqualToEsFilter } from './processors/lessThanOrEqualTo';
import { nameDbFilter, nameEsFilter } from './processors/name';
import { numericDbFilter, numericEqDbFilter, numericEqEsFilter, numericEsFilter } from './processors/numeric';
import { organizationDbFilter, organizationEsFilter } from './processors/organization';
import { personDbFilter, personEsFilter } from './processors/person';
import { pppLoanDbFilter, pppLoanEsFilter } from './processors/pppLoan';
import {
  propensityEqDbFilter,
  propensityEqEsFilter,
  propensityGteDbFilter,
  propensityGteEsFilter
} from './processors/propensity';
import {
  propertyDbFilter,
  propertyEsFilter,
  propertyOwnerDbFilter,
  propertyOwnerEsFilter
} from './processors/property';
import { raceDbFilter, raceEsFilter } from './processors/race';
import { yearDbFilter, yearEsFilter } from './processors/year';
import { yearsAgoDbFilter, yearsAgoEsFilter } from './processors/yearsago';
import { participationDbFilter, participationEsFilter } from './processors/participation';
import { inDbFilter, inEsFilter } from './processors/in';
import { notInDbFilter, notInEsFilter } from './processors/notin';
import { factoidDbFilter, factoidEsFilter } from './processors/factoid';
import { rpaDbFilter, rpaEsFilter } from './processors/rpa';
import { nestedDbFilter, nestedEsFilter } from './processors/nested';
import { phoneExistsDbFilter, phoneExistsEsFilter } from './processors/phoneExists';
import { emailExistsDbFilter, emailExistsEsFilter } from './processors/emailExists';
import { matchTypeDbFilter, matchTypeEsFilter } from './processors/matchType';

class ParameterProcessorRegistry {
  private registry: any;
  private static INSTANCE = new ParameterProcessorRegistry();

  private constructor() {
    this.registry = {};
    this.initDefaultFilters();
  }

  // these filters automatically map url parameters to db and elasticsearch query clauses
  private initDefaultFilters() {
    // yearsago
    this.registerParameterProcessor('yearsago', yearsAgoDbFilter, yearsAgoEsFilter);

    // propgte
    this.registerParameterProcessor('propgte', propensityGteDbFilter, propensityGteEsFilter);

    // propgte
    this.registerParameterProcessor('propeq', propensityEqDbFilter, propensityEqEsFilter);

    // year
    this.registerParameterProcessor('year', yearDbFilter, yearEsFilter);

    // name
    this.registerParameterProcessor('name', nameDbFilter, nameEsFilter);

    // numeric
    this.registerParameterProcessor('numeric', numericDbFilter, numericEsFilter);

    // numeric eq
    this.registerParameterProcessor('numeq', numericEqDbFilter, numericEqEsFilter);

    // equalsIgnoreCase
    this.registerParameterProcessor('eic', equalsIgnoreCaseDbFilter, equalsIgnoreCaseEsFilter);

    // includesIgnoreCase
    this.registerParameterProcessor('iic', includesIgnoreCaseDbFilter, includesIgnoreCaseEsFilter);

    // match type filter
    this.registerParameterProcessor('mt', matchTypeDbFilter, matchTypeEsFilter);

    // equals
    this.registerParameterProcessor('eq', equalsDbFilter, equalsEsFilter);

    // property
    this.registerParameterProcessor('property', propertyDbFilter, propertyEsFilter);

    // owner
    this.registerParameterProcessor('owner', propertyOwnerDbFilter, propertyOwnerEsFilter);

    // exists
    this.registerParameterProcessor('exists', existsDbFilter, existsEsFilter);

    // fe
    this.registerParameterProcessor('fe', fieldExistsDbFilter, fieldExistsEsFilter);

    // flag
    this.registerParameterProcessor('flag', flagDbFilter, flagEsFilter);

    // group by
    this.registerParameterProcessor('gb', groupByDbFilter, groupByEsFilter);

    // clf
    this.registerParameterProcessor('clf', casesByLawfirmDbFilter, casesByLawfirmEsFilter);

    // person
    this.registerParameterProcessor('person', personDbFilter, personEsFilter);

    // ppp
    this.registerParameterProcessor('ppp', pppLoanDbFilter, pppLoanEsFilter);

    // geo radius
    this.registerParameterProcessor('geo', geoRadiusDbFilter, geoRadiusEsFilter);

    // organization
    this.registerParameterProcessor('organization', organizationDbFilter, organizationEsFilter);

    // race
    this.registerParameterProcessor('race', raceDbFilter, raceEsFilter);

    // gte
    this.registerParameterProcessor('gte', greaterThanOrEqualToDbFilter, greaterThanOrEqualToEsFilter);

    // lte
    this.registerParameterProcessor('lte', lessThanOrEqualToDbFilter, lessThanOrEqualToEsFilter);

    // rangeFrom
    this.registerParameterProcessor('rangeFrom', dateRandgeDbFilter, dateRangeEsFilter);

    // rangeTo
    this.registerParameterProcessor('rangeTo', dateRandgeDbFilter, dateRangeEsFilter);

    // caseName
    this.registerParameterProcessor('caseName', caseNameDbFilter, caseNameEsFilter);

    // em
    this.registerParameterProcessor('em', exactMatchDbFilter, exactMatchEsFilter);

    // vp
    this.registerParameterProcessor('vp', participationDbFilter, participationEsFilter);

    // in
    this.registerParameterProcessor('in', inDbFilter, inEsFilter);

    // notin
    this.registerParameterProcessor('notin', notInDbFilter, notInEsFilter);

    // f
    this.registerParameterProcessor('f', factoidDbFilter, factoidEsFilter);

    // rpa -- Roseland Party Affiliation
    this.registerParameterProcessor('rpa', rpaDbFilter, rpaEsFilter);

    // n -- nested
    this.registerParameterProcessor('n', nestedDbFilter, nestedEsFilter);

    // pe -- phone exists
    this.registerParameterProcessor('pe', phoneExistsDbFilter, phoneExistsEsFilter);

    // ee -- email exists
    this.registerParameterProcessor('ee', emailExistsDbFilter, emailExistsEsFilter);
  }

  public static getInstance() {
    return ParameterProcessorRegistry.INSTANCE;
  }

  public registerParameterProcessor(key: string, dbFunc: any, esFunc: any) {
    this.registry[key] = {
      db: dbFunc,
      elasticSearch: esFunc
    };
  }

  public buildProcessor(
    searchParams: URLSearchParams,
    field: string,
    type: string,
    filterValue: any[],
    source: DataSource
  ) {
    //key word of view_all ignores the filter
    if (filterValue.length > 0 && 'view_all' === filterValue[0]) {
      return {};
    }

    // type eg: eq, contains, yearsago
    const filterBuilder = this.registry[type];

    if (isNil(filterBuilder)) {
      if (env.NODE_ENV !== 'production') {
        console.warn('Filter builder not registered for type ' + type);
      }
      return {};
    }

    // db or elasticSearch
    const builderFunction = filterBuilder[source || 'db'];

    if (isNil(builderFunction)) {
      if (env.NODE_ENV !== 'production') {
        console.warn('Builder function not registered for type ' + type + ' and source ' + (source || 'db (default)'));
      }
      return {};
    }

    return builderFunction(field, searchParams, filterValue);
  }
}

export default ParameterProcessorRegistry;
