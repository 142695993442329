import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CsvProps } from '../../components/DownloadCsv';
import { setAppLoading } from '../../store/app';
import { loadDefaultSort, useFilterQueryCriteriaFromUrl } from '../../utils/filter';

const CASES_FRAGMENT = gql`
  fragment Case on ViewCase {
    id
    name
    courtId
    courtName
    filledDate
    state
    category
    subcategory
    type
    judgeId
    judgeName
    courtType
    status
    lawFirmIds
    lawFirmNames
    awardAmount
  }
`;
const CASES_LISTING = gql`
  query CasesListing($filter: JSONObject!, $offset: Int!, $orderBy: [ViewCasesOrderBy!]) {
    cases: viewCasesConnection(first: 150, elasticSearch: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        ...Case
      }
      totalCount
      totalAggCount
    }
  }

  ${CASES_FRAGMENT}
`;

const CASE_STATE_QUERY = gql`
  query casesState($filter: MViewCaseFilter!) {
    options: mViewCasesConnection(filter: $filter) {
      groupedAggregates(groupBy: STATE) {
        sum: distinctCount {
          total: id
        }
        keys
      }
    }
  }
`;

// case type
const CASE_TYPE_QUERY = gql`
  query casesType($filter: MViewCaseFilter!) {
    options: mViewCasesConnection(filter: $filter) {
      groupedAggregates(groupBy: TYPE) {
        sum: distinctCount {
          total: id
        }
        keys
      }
    }
  }
`;

// court type
const COURT_TYPE_QUERY = gql`
  query courtsType($filter: MViewCaseFilter!) {
    options: mViewCasesConnection(filter: $filter) {
      groupedAggregates(groupBy: COURT_TYPE) {
        sum: distinctCount {
          total: id
        }
        keys
      }
    }
  }
`;

// court name
const COURT_NAME_QUERY = gql`
  query casesCourtName($filter: MViewCaseFilter!) {
    options: mViewCasesConnection(filter: $filter) {
      groupedAggregates(groupBy: [COURT_ID, COURT_NAME]) {
        sum: distinctCount {
          total: id
        }
        keys
      }
    }
  }
`;

const CATEGORY_QUERY = gql`
  query casesCategory($filter: MViewCaseFilter!) {
    options: mViewCasesConnection(filter: $filter) {
      groupedAggregates(groupBy: CATEGORY) {
        sum: distinctCount {
          total: id
        }
        keys
      }
    }
  }
`;

const SUBCATEGORY_QUERY = gql`
  query casesSubCategory($filter: MViewCaseFilter!) {
    options: mViewCasesConnection(filter: $filter) {
      groupedAggregates(groupBy: SUBCATEGORY) {
        sum: distinctCount {
          total: id
        }
        keys
      }
    }
  }
`;
const STATUS_QUERY = gql`
  query casesStatus($filter: MViewCaseFilter!) {
    options: mViewCasesConnection(filter: $filter) {
      groupedAggregates(groupBy: STATUS) {
        sum: distinctCount {
          total: id
        }
        keys
      }
    }
  }
`;

const CASE_JUDGE_NAME_QUERY = gql`
  query casesJudgeNameCasesConnection($filter: MViewCaseFilter!) {
    options: mViewCasesConnection(filter: $filter) {
      groupedAggregates(groupBy: [JUDGE_ID, JUDGE_NAME]) {
        sum: distinctCount {
          total: id
        }
        keys
      }
    }
  }
`;

const CASE_LAWFIRM_NAME_QUERY = gql`
  query casesLawfirmName($filter: ViewLawfirmsByCaseFilter!) {
    options: viewLawfirmsByCasesConnection(filter: $filter) {
      groupedAggregates(groupBy: [LAW_FIRM_ID, LAW_FIRM_NAME]) {
        sum: distinctCount {
          total: caseId
        }
        keys
      }
    }
  }
`;

export const useCaseJudgeNameQuery = () => {
  return useLazyQuery(CASE_JUDGE_NAME_QUERY);
};
export const useCaseLawfirmNameQuery = () => {
  return useLazyQuery(CASE_LAWFIRM_NAME_QUERY);
};
export const useCaseTypeQuery = () => {
  return useLazyQuery(CASE_TYPE_QUERY);
};
export const useCaseCourtNameQuery = () => {
  return useLazyQuery(COURT_NAME_QUERY);
};
export const useCourtTypeQuery = () => {
  return useLazyQuery(COURT_TYPE_QUERY);
};
export const useCaseStateQuery = () => {
  return useLazyQuery(CASE_STATE_QUERY);
};

export const useCaseCategoryQuery = () => {
  return useLazyQuery(CATEGORY_QUERY);
};
export const useCaseSubCategoryQuery = () => {
  return useLazyQuery(SUBCATEGORY_QUERY);
};
export const useCaseStatusQuery = () => {
  return useLazyQuery(STATUS_QUERY);
};
export const useAllCasesListingQuery = (offset: number, _sort: string[]) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'elasticSearch');

  const [sort, setSort] = useState(_sort);
  
  useEffect(() => {
    if (sort.length < 1) {
      setSort(loadDefaultSort(searchParams, sort, ['NAME_ASC']));
      setSearchParams(searchParams);
    }
  }, [sort, setSearchParams, searchParams]);

  const result = useQuery(CASES_LISTING, {
    variables: {
      ...queryCriteria,
      offset: offset,
      orderBy: sort
    }
  });

  // todo: add result;
  useEffect(() => {
    setAppLoading(true);
    result.refetch(queryCriteria).finally(() => setAppLoading(false));
  }, [queryCriteria]);

  return result;
};

const CASES_FOR_DOWNLOAD = gql`
  query CasesDownload($first: Int!, $filter: JSONObject!, $offset: Int!, $orderBy: [ViewCasesOrderBy!]) {
    csv: viewCasesConnection(first: $first, elasticSearch: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        ...Case
      }
    }
  }
  ${CASES_FRAGMENT}
`;

export const useCsvPropsForDownload = () => {
  const [searchParams] = useSearchParams();
  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'elasticSearch');

  const csvProps: CsvProps = {
    query: CASES_FOR_DOWNLOAD,
    vars: {
      ...queryCriteria,
      offset: 0,
      first: 10000,
      orderBy: ['NAME_ASC']
    }
  };

  return csvProps;
};

const TOTALS_QUERY = gql`
  query TotalsQuery {
    dashboardTotals: viewCasesConnection(elasticSearch: { index: "cases" }) {
      totalCount
    }
  }
`;

export const useCasesTotalsQuery = () => {
  return useQuery(TOTALS_QUERY, {});
};

const JUDGE_NAME_BY_ID = gql`
  query TypeById($ids: [BigInt!]) {
    result: mViewCasesConnection(filter: { judgeId: { in: $ids } }, first: 1) {
      nodes {
        judgeName
        judgeId
      }
    }
  }
`;

export const useJudgeNameById = () => {
  return useLazyQuery(JUDGE_NAME_BY_ID);
};

const LAW_FIRM_NAME_BY_ID = gql`
  query TypeById($ids: [BigInt!]) {
    result: mViewLawFirmsConnection(filter: { id: { in: $ids } }, first: 1) {
      nodes {
        name
        id
        address
      }
    }
  }
`;

export const useLawFirmNameById = () => {
  return useLazyQuery(LAW_FIRM_NAME_BY_ID);
};

const COURT_NAME_BY_ID = gql`
  query TypeById($ids: [BigInt!]) {
    result: mViewCourtsConnection(filter: { id: { in: $ids } }, first: 1) {
      nodes {
        name
        id
      }
    }
  }
`;

export const useCourtNameById = () => {
  return useLazyQuery(COURT_NAME_BY_ID);
};
