import { faBars, faClose } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import {
  Navbar as BootstapNavBar,
  Nav as BootstrapNav,
  NavDropdown as BootstrapNavDropdown,
  NavbarBrandProps,
  NavbarProps,
  NavDropdownProps,
  NavProps
} from 'react-bootstrap';
import { NavbarToggleProps } from 'react-bootstrap/esm/NavbarToggle';
import styled from 'styled-components';
import navBarCollapse from '../../img/menu-collapse.svg';
import navBarExpand from '../../img/menu-expand.svg';
import { useExpandedNav } from '../../store/app';
import Button from '../Button';
import Icon from '../Icon';
import Logo from '../Logo';
import { paths } from '../routes/routes';

export const NavBar = styled(({ children, ...rest }: NavbarProps) => {
  return <BootstapNavBar {...rest}>{children}</BootstapNavBar>;
})`
  padding: 0;
  position: relative;
  background-color: var(--nav-bg);
`;
export const NavBarToggle = styled(({ className, ...rest }: NavbarToggleProps) => {
  const expandedNav = useExpandedNav();
  return (
    <Button
      variant="white"
      className={`${className} navbar-toggle`}
      src={expandedNav ? navBarCollapse : navBarExpand}
      alt="Nav bar toggler"
      {...rest}
    >
      <Icon size="2x" icon={expandedNav ? faClose : faBars} />
    </Button>
  );
})`
  &.btn.btn-white,
  &.btn.btn-white:hover,
  &.btn.btn-white:focus {
    display: none;
    border: none;
    box-shadow: none;
    cursor: pointer;
    padding: 0;
    border: 0;
    box-shadow: none;
    outline: none;
    margin-right: 24px;
    width: 20px;

    @media (max-width: 767.98px) {
      display: block;
    }
  }
`;
export const NavDropdown = styled(({ as, ...rest }: NavDropdownProps) => {
  return <BootstrapNavDropdown {...rest} />;
})`
  padding: var(--nav-padding);
`;

export const Nav = styled(({ as, ...rest }: NavProps) => {
  return <BootstrapNav {...rest} />;
})`
  padding: var(--nav-padding);
`;
export const NavItem = styled(({ ...rest }) => {
  return <BootstrapNav.Item {...rest} />;
})``;
export const NavBarBrand = styled(({ className }: NavbarBrandProps) => {
  useExpandedNav();
  return (
    <BootstapNavBar.Brand className={className} href={paths.dashboard}>
      <Logo />
    </BootstapNavBar.Brand>
  );
})`
  display: inline-block;

  .logo {
    margin-bottom: 0;
  }
`;
