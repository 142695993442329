import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CsvProps } from '../../components/DownloadCsv';
import { setAppLoading } from '../../store/app';
import { loadDefaultSort, useFilterQueryCriteriaFromUrl } from '../../utils/filter';

const LAWFIRMS_LISTING = gql`
  query LawFirmsListing($filter: MViewLawFirmFilter!, $offset: Int!, $orderBy: [MViewLawFirmsOrderBy!]) {
    lawfirms: mViewLawFirmsConnection(first: 150, filter: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        id
        name
        address
        city
        state
        zip
        website
        firmLogoUrl
        numLawyers
        numCases
      }
      totalCount
    }
  }
`;

const STATE_COUNT_QUERY = gql`
  query StateCounts {
    states: mViewLawFirmsConnection(filter: { state: { isNull: false } }, orderBy: NATURAL) {
      groupedAggregates(groupBy: STATE) {
        keys
        sum: distinctCount {
          total: id
        }
      }
    }
  }
`;

export const useStateLawfirmCountQuery = () => {
  return useLazyQuery(STATE_COUNT_QUERY);
};

export const useAllLawfirmsListingQuery = (offset: number, _sort: string[]) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'db');

  const [sort, setSort] = useState(_sort);
  
  useEffect(() => {
    if (sort.length < 1) {
      setSort(loadDefaultSort(searchParams, sort, ['NAME_ASC']));
      setSearchParams(searchParams);
    }
  }, [sort, setSearchParams, searchParams]);

  const result = useQuery(LAWFIRMS_LISTING, {
    variables: {
      ...queryCriteria,
      offset: offset,
      orderBy: sort
    }
  });

  // todo: add result;
  useEffect(() => {
    setAppLoading(true);
    result.refetch(queryCriteria).finally(() => setAppLoading(false));
  }, [queryCriteria]);

  return result;
};

const LAWFIRMS_FOR_DOWNLOAD = gql`
  query LawfirmsDownload($first: Int!, $filter: MViewLawFirmFilter!, $offset: Int!, $orderBy: [MViewLawFirmsOrderBy!]) {
    csv: mViewLawFirmsConnection(first: $first, filter: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        id
        name
        address
        city
        state
        zip
        website
        firmLogoUrl
        numLawyers
        numCases
      }
    }
  }
`;

export const useCsvPropsForDownload = () => {
  const [searchParams] = useSearchParams();
  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'db');

  const csvProps: CsvProps = {
    query: LAWFIRMS_FOR_DOWNLOAD,
    vars: {
      ...queryCriteria,
      offset: 0,
      first: 10000,
      orderBy: ['NAME_ASC']
    }
  };

  return csvProps;
};

const TOTALS_QUERY = gql`
  query TotalsQuery {
    dashboardTotals: mViewLawFirmsConnection {
      totalCount
    }
  }
`;

export const useLawFirmsTotalsQuery = () => {
  return useQuery(TOTALS_QUERY, {});
};
