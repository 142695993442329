import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CsvProps } from '../../components/DownloadCsv';
import { loadDefaultSort, useFilterQueryCriteriaFromUrl } from '../../utils/filter';

const MOBILE_HOME_LISTING = gql`
  query MobileHomeListing($filter: MViewMobileHomeFilter!, $offset: Int!, $orderBy: [MViewMobileHomesOrderBy!]) {
    mobileHome: mViewMobileHomes(first: 150, filter: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        addressId
        name
        address
        cityId
        city
        countyId
        county
        state
        zip
        voterCount
      }
      totalCount
    }
  }
`;

export const useMobileHomeQuery = (offset: number, _sort: string[]) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'db');

  const [sort, setSort] = useState(_sort);
  
  useEffect(() => {
    if (sort.length < 1) {
      setSort(loadDefaultSort(searchParams, sort, ['NAME_ASC']));
      setSearchParams(searchParams);
    }
  }, [sort, setSearchParams, searchParams]);

  const filter = {
    variables: {
      ...queryCriteria,
      offset: offset,
      orderBy: sort
    },
    context: { version: '2' }
  };

  const result = useQuery(MOBILE_HOME_LISTING, filter);

  return result;
};

const STATE_COUNT_QUERY = gql`
  query StateCounts {
    states: mViewMobileHomes(filter: { state: { isNull: false } }, orderBy: NATURAL) {
      groupedAggregates(groupBy: STATE) {
        keys
        sum: distinctCount {
          total: addressId
        }
      }
    }
  }
`;

export const useMobileHomeStatesQuery = () => {
  return useLazyQuery(STATE_COUNT_QUERY, { context: { version: '2' } });
};

const COUNTIES_QUERY = gql`
  query CountiesByState($filter: MViewMobileHomeFilter!) {
    counties: mViewMobileHomes(filter: $filter) {
      groupedAggregates(groupBy: [COUNTY_ID, COUNTY, STATE]) {
        keys
        sum: distinctCount {
          total: addressId
        }
      }
    }
  }
`;

export const useMobileHomeCounties = () => {
  return useLazyQuery(COUNTIES_QUERY, { context: { version: '2' } });
};

const CITIES_QUERY = gql`
  query CitiesForState($filter: MViewMobileHomeFilter!) {
    cities: mViewMobileHomes(filter: $filter) {
      groupedAggregates(groupBy: [CITY_ID, CITY, COUNTY, COUNTY_ID, STATE]) {
        keys
        sum: distinctCount {
          total: addressId
        }
      }
    }
  }
`;

export const useMobileHomeCities = () => {
  return useLazyQuery(CITIES_QUERY, { context: { version: '2' } });
};

const TOTALS_QUERY = gql`
  query TotalsQuery {
    dashboardTotals: mViewMobileHomes {
      totalCount
    }
  }
`;

export const useMobileHomeTotalsQuery = () => {
  return useQuery(TOTALS_QUERY, { context: { version: '2' } });
};

const MOBILE_HOME_FOR_DOWNLOAD = gql`
  query MobileHomeDownload(
    $first: Int!
    $filter: MViewMobileHomeFilter!
    $offset: Int!
    $orderBy: [MViewMobileHomesOrderBy!]
  ) {
    csv: mViewMobileHomes(first: $first, filter: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        name
        address
        city
        county
        state
        zip
        voterCount
      }
    }
  }
`;

export const useCsvPropsForDownload = () => {
  const [searchParams] = useSearchParams();
  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'db');

  const csvProps: CsvProps = {
    query: MOBILE_HOME_FOR_DOWNLOAD,
    vars: {
      ...queryCriteria,
      offset: 0,
      first: 10000,
      orderBy: ['NAME_ASC']
    },
    context: { version: '2' }
  };

  return csvProps;
};
