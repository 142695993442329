import React, { memo } from 'react';
import { Breadcrumb as BootstrapBreadcrumb, BreadcrumbItemProps, BreadcrumbProps } from 'react-bootstrap';
import EllipsisText from 'react-ellipsis-text';
import styled from 'styled-components';
import { setExpandedNav, useAppBreadcrumb, useExpandedNav } from '../../store/app';
import { useClearState } from '../../utils/filter';
import SchemaView from '../SchemaView';
import { NavBarToggle } from '../layouts/NavBar';

const Breadcrumb = styled(
  memo(({ ...rest }: BreadcrumbProps) => {
    const breadcrumbs = useAppBreadcrumb();
    const clearState = useClearState();
    const expandedNav = useExpandedNav();
    return (
      <>
       
        <BootstrapBreadcrumb {...rest}>
          <NavBarToggle onClick={() => setExpandedNav(!expandedNav)} />
          {breadcrumbs.map((b: any, i: number) => {
            const active = i === breadcrumbs.length - 1;
            return (
              <BreadcrumbItem key={b.path} active={active} onClick={active ? null : () => clearState(b.path)}>
                <EllipsisText text={b.label} length={100} />
                {active ? <SchemaView /> : null}
              </BreadcrumbItem>
            );
          })}
        </BootstrapBreadcrumb>
      </>
    );
  })
)`
  font-size: 22px;
  font-weight: 600;
  color: var(--breadcrumb-color);
  position: absolute;
  left: 12px;
  top: 8px;

  .breadcrumb {
    @media (max-width: 767.98px) {
      font-size: 16px;
      margin-bottom: 0;
    }
  }

  .schema-view .btn {
    padding: 0;
    border: 0;
    padding-left: 12px;
    position: relative;
    top: -2px;
  }
`;

export const BreadcrumbItem = styled(
  memo(({ as, ...rest }: BreadcrumbItemProps) => {
    return <BootstrapBreadcrumb.Item {...rest} />;
  })
)`
  a {
    color: var(--breadcrumb-color);
    text-decoration: none;
  }
  & + .breadcrumb-item:before {
    color: var(--breadcrumb-color);
    content: '›';
    position: relative;
    margin-right: 10px;
    left: 6px;
    line-height: 1;
    font-size: 28px;
    @media (max-width: 767.98px) {
      font-size: 22px;
    }
  }

  &.active {
    color: var(--breadcrumb-active-color);
  }
`;

export default Breadcrumb;
