import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CsvProps } from '../../components/DownloadCsv';
import { setAppLoading } from '../../store/app';
import { loadDefaultSort, useFilterQueryCriteriaFromUrl } from '../../utils/filter';

const CANDIDATE_LIST_FRAGMENT = gql`
  fragment CandidateFragment on MViewCandidateFiling {
    id
    candidateId
    lastName
    firstName
    address1
    address2
    city
    state
    zip
    partyName
    officeName
    elecDate
    electionType
  }
`;

const CANDIDATES_LISTING = gql`
  query CandidatesListing(
    $filter: MViewCandidateFilingFilter!
    $offset: Int!
    $orderBy: [MViewCandidateFilingsOrderBy!]
  ) {
    candidates: mViewCandidateFilingsConnection(first: 150, filter: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        ...CandidateFragment
      }
      totalCount
    }
  }
  ${CANDIDATE_LIST_FRAGMENT}
`;

const CANDIDATES_FOR_DOWNLOAD = gql`
  query CandidatesForDownload($filter: MViewCandidateFilingFilter!, $orderBy: [MViewCandidateFilingsOrderBy!]) {
    csv: mViewCandidateFilingsConnection(first: 250000, filter: $filter, orderBy: $orderBy) {
      nodes {
        lastName
        firstName
        address1
        address2
        city
        state
        zip
        partyName
        officeName
        elecDate
        electionType
        dataSourceUrl
      }
    }
  }
`;

const CANDIDATE_ELECTION_YEARS_QUERY = gql`
  query CandidatesYear {
    options: mViewCandidateFilingsConnection(filter: { elecYear: { isNull: false } }) {
      groupedAggregates(groupBy: ELEC_YEAR) {
        sum: distinctCount {
          total: id
        }
        keys
      }
    }
  }
`;

export const useCandidateElectionYearsQuery = () => {
  return useLazyQuery(CANDIDATE_ELECTION_YEARS_QUERY);
};

const CANDIDATE_OFFICES_QUERY = gql`
  query candidatesOffice {
    options: mViewCandidateFilingsConnection(filter: { officeName: { isNull: false } }) {
      groupedAggregates(groupBy: OFFICE_NAME) {
        sum: distinctCount {
          total: id
        }
        keys
      }
    }
  }
`;

export const useCandidateOfficesQuery = () => {
  return useLazyQuery(CANDIDATE_OFFICES_QUERY);
};

const CANDIDATE_PARTIES_QUERY = gql`
  query CandidatesParty {
    options: mViewCandidateFilingsConnection(filter: { partyName: { isNull: false } }) {
      groupedAggregates(groupBy: PARTY_NAME) {
        sum: distinctCount {
          total: id
        }
        keys
      }
    }
  }
`;

export const useCandidatePartiesQuery = () => {
  return useLazyQuery(CANDIDATE_PARTIES_QUERY);
};

const CANDIDATE_STATES_QUERY = gql`
  query CandidatesState {
    options: mViewCandidateFilingsConnection(filter: { state: { isNull: false } }, orderBy: NATURAL) {
      groupedAggregates(groupBy: STATE) {
        sum: distinctCount {
          total: id
        }
        keys
      }
    }
  }
`;

export const useStatesForCandidates = () => {
  return useLazyQuery(CANDIDATE_STATES_QUERY);
};
export const useAllCandidatesListingQuery = (offset: number, _sort: string[]) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'db');

  const [sort, setSort] = useState(_sort);
  
  useEffect(() => {
    if (sort.length < 1) {
      setSort(loadDefaultSort(searchParams, sort, ['LAST_NAME_ASC', 'FIRST_NAME_ASC']));
      setSearchParams(searchParams);
    }
  }, [sort, setSearchParams, searchParams]);

  const result = useQuery(CANDIDATES_LISTING, {
    variables: {
      ...queryCriteria,
      offset: offset,
      orderBy: sort
    }
  });

  // todo: add result;
  useEffect(() => {
    setAppLoading(true);
    result.refetch(queryCriteria).finally(() => setAppLoading(false));
  }, [queryCriteria]);

  return result;
};

export const useCsvPropsForDownload = () => {
  const [searchParams] = useSearchParams();

  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'db');

  const csvProps: CsvProps = {
    query: CANDIDATES_FOR_DOWNLOAD,
    vars: {
      ...queryCriteria,
      orderBy: ['LAST_NAME_ASC', 'FIRST_NAME_ASC']
    }
  };

  return csvProps;
};

const TOTALS_QUERY = gql`
  query TotalsQuery {
    dashboardTotals: mViewCandidateFilingsConnection {
      totalCount
    }
  }
`;

export const useCandidatesTotalsQuery = () => {
  return useQuery(TOTALS_QUERY, {});
};
