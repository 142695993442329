import { useState, useEffect } from 'react';
import Bowser from 'bowser';

const useBrowserDetection = () => {
  const [browser, setBrowser] = useState<any>(null);

  useEffect(() => {
    const handleDeviceDetection = () => {
      const parsedBrowser = Bowser.parse(navigator.userAgent);
      if (parsedBrowser.platform.vendor === 'Apple' && parsedBrowser.os.name === 'macOS') {
        if (navigator.maxTouchPoints && navigator.maxTouchPoints > 0) {
          parsedBrowser.os.name = 'iOS';
          parsedBrowser.platform.type = 'tablet';
          parsedBrowser.platform.model = 'iPad';
        }
      }
      setBrowser(parsedBrowser);
    };

    handleDeviceDetection();
  }, []);

  return browser;
};

export default useBrowserDetection;
