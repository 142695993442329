import { debounce, get, identity, isNil, set, trim } from 'lodash';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { setConfirmModal } from '../store/modal';

/**
 * Helper for binding form state
 */
export const useFormState = (state: any, setState: (state: any) => void) => {
  return useCallback(
    (key: string) => {
      return (
        fromEvent = (e: Event | string) => ((e as Event)?.target as HTMLInputElement)?.value,
        fromState: (v: any) => any = identity,
        prop: string = 'value'
      ) => ({
        [prop]: fromState(get(state, key, '')),
        onChange: (e: Event) => setState({ ...state, ...set(state, key, fromEvent(e)) })
      });
    },
    [state, setState]
  );
};

export const deepClone = (obj: any) => {
  return JSON.parse(JSON.stringify(obj));
};

export const useDebounce = (fnToDebounce: any, durationInMs = 200) => {
  let dbFun = fnToDebounce;
  if (isNil(dbFun)) {
    dbFun = () => {};
  }

  if (isNaN(durationInMs)) {
    throw new TypeError('durationInMs for debounce should be a number');
  }

  if (dbFun == null) {
    throw new TypeError('fnToDebounce cannot be null');
  }

  if (typeof dbFun !== 'function') {
    throw new TypeError('fnToDebounce should be a function');
  }

  return useCallback(debounce(dbFun, durationInMs), [dbFun, durationInMs]);
};

const varRegex = new RegExp(/\[([\w|.]*)\]/, 'gi');
export const parseLinkVariables = (url?: string | null): string[] => {
  if (isNil(url) || trim(url) === '') {
    return [];
  }
  const result: string[] = [];
  let matches: any = null;
  while ((matches = varRegex.exec(url as string)) !== null) {
    if (matches.length > 0) {
      result.push(matches[0].replace('[', '').replace(']', ''));
    }
  }

  return result.filter((r?: string) => !isNil(r) && trim(r) !== '');
};

export const useNavigateDirtyCheck = (route: string, message?: string) => {
  const navigate = useNavigate();

  return (dirty: boolean) => {
    if (dirty) {
      setConfirmModal({
        message: message || 'You have unsaved changes.',
        confirmLabel: 'Abandon Changes',
        open: true,
        onConfirm: () => {
          navigate(route);
        }
      });
    } else {
      navigate(route);
    }
  };
};
